import commonApi from '../_EXT_API'

export default class game extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'personal'
        let name = 'personal'
        opt = {localKey, name}
        super(opt)
    }
	async getPersonalUser(user_code) {
		let options = {
			method: "get",
			user_code,
			data:{
				
			}
		}
		let res = await this.requestAction('getPersonalUser', options)
		return res
	}
	async getPersonalAI(user_code,ai_count) {
		ai_count = ai_count || 0
		let options = {
			method: "get",
			user_code,
			data:{
				user_code,
				ai_count,
			}
		}
		return this.requestAction('getPersonalAI', options)
	}
	async getPersonalPuzzle(user_code,puzzle_exercise_count) {
		let options = {
			method: "get",
			user_code,
			data:{
				exercise_size:puzzle_exercise_count,
			}
		}
		return this.requestAction('getPersonalPuzzle', options)
	}
	async schoolInfo(opt){
		opt = opt || {}
		let {school_id} = opt
        school_id = school_id || this.PARAMS.school_id
		let options = {
			method: "get",
			school_id,
			...opt
		}
		return this.requestAction('schoolInfo', options)
	}

	async userInfo(opt){
		opt = opt || {}
		let {user_code} = opt
		let options = {
			method: "get",
			data:{
				user_code,
			},
			...opt
		}
		return this.requestAction('userInfo', options)
	}

	async statisticsInfo(opt){
		opt = opt || {}
		let {user_code, game_size} = opt
		let options = {
			method: "get",
			data:{
				user_code,
				game_size
			},
			...opt
		}
		return this.requestAction('statisticsInfo', options)
	}
	async puzzleInfo(opt){
		opt = opt || {}
		let {user_code, exercise_size} = opt
		// let data = {
		// 	user_code,
		// }
		// if(exercise_size) data.exercise_size = exercise_size
		let options = {
			method: "get",
			data: {
				user_code,
				exercise_size
			},
			...opt
		}
		return this.requestAction('puzzleInfo', options)
	}

	async gameInfo(opt){
		opt = opt || {}
		let {user_code, game_size} = opt
		let options = {
			method: "get",
			data: {
				user_code,
				game_size
			},
			...opt
		}
		return this.requestAction('gameInfo', options)
	}
	
}
