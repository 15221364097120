<!--  -->
<template>
    <div class='page-personal-summary'>
        <div class="summary-body">
            <div class="summary-head max-width  text-center">
                <span class="text-normal-a bold">{{nickname}}</span>
                <span class="text-normal-a bold">{{pageText.t1}}</span>
            </div>
            <div class="summary-content light-dark-color">
                <div class="content-item a">
                    <div class="head">
                        <div class="title-icon"></div>
                        <div class="title-text bold">{{pageText.t2}}</div>
                    </div>
					<!-- <div class="dotLineBox">
						<div class="dot-line"></div>
						<div class="dot-text">
							<div class="b-text text-large-c text-center">
								<div class="rate-big bold">{{userGameWinRate | winrate}}</div>
								<div class="rate-small text-small">%</div>
								<div class="s-text text-mini  text-center">胜率</div>
							</div>
							
						</div>
					</div> -->
                    <div class="body">
                        <div class="body-item">
                            <div class="b-i-item cursor-pointer" v-for="v in gameCountList" :key="v.value" :class="{'active': gameCountValue == v.value}" @click="gameChamge(v)">
                                <span class="text-small-flex">{{v.title}}</span>
                            </div>
                        </div>
                        <div class="body-item b">
                            <div class="pie-chat">
                                <div class="chat-value">
                                    <div class="value-line"></div>
                                    <div class="value-text">
                                        <div class="text-v">
                                            <span class="text-large-c-flex">{{userGameWinRate | fixed0}}</span>
                                            <span class="text-normal-flex">%</span>
                                        </div>
                                        <div class="text-i">
                                            <span class="text-mini-flex">胜率</span>
                                        </div>
                                    </div>
                                </div>
                                <personalPieChart :percentageInfo="userGameWinRate" :idName="1" ref="game-pie"></personalPieChart>
                            </div>
                            <!-- <div class="pie-text">
                                <div class="text-large-c text-center">{{userGameWinRate | winrate}}%</div>
                                <div class="text-mini  text-center">胜率</div>
                            </div> -->
                        </div>
                    </div>
                    <div class="foot">
                        <div class="foot-item" v-for="v in gameFootList" :key="v.params">
                            <div class="it-da">
                                <span class="text-large bold">{{gameInfo[v.params]}}</span>
                                <span class="text-small-flex">{{v.unit}}</span>
                            </div>
                            <div class="it-db ">
                                <span class=" text-small-flex">{{v.title}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item b">
                    <div class="head">
                        <div class="title-icon"></div>
                        <div class="title-text bold">{{pageText.t3}}</div>
                    </div>
					<!-- <div class="dotLineBox">
						<div class="dot-line"></div>
						<div class="dot-text">
							<div class="b-text text-large-c text-center">
								<div class="rate-big bold">{{userPuzzleWinRate | winrate}}</div>
								<div class="rate-small text-small">%</div>
								<div class="s-text text-mini  text-center">正确率</div>
							</div>
						</div>
					</div> -->
                    <div class="body">
                        <div class="body-item">
                            <div class="b-i-item cursor-pointer" v-for="v in puzzleCountList" :key="v.value" :class="{'active': puzzleCountValue == v.value}"  @click="puzzleChamge(v)">
                                <span class="text-small-flex">{{v.title}}</span>
                            </div>
                        </div>
                        <div class="body-item b">
                            <div class="pie-chat">
                                <div class="chat-value">
                                    <div class="value-line"></div>
                                    <div class="value-text">
                                        <div class="text-v">
                                            <span class="text-large-c-flex">{{userPuzzleWinRate | fixed0}}</span>
                                            <span class="text-normal-flex">%</span>
                                        </div>
                                        <div class="text-i">
                                            <span class="text-mini-flex">正确率</span>
                                        </div>
                                    </div>
                                </div>
                                <personalPieChart :percentageInfo="userPuzzleWinRate" :idName="2" ref="puzzle-pie"></personalPieChart>
                            </div>
                            <!-- <div class="pie-text">
                                <div class="text-large-c text-center">{{userPuzzleWinRate | winrate}}%</div>
                                <div class="text-mini  text-center">正确率</div>
                            </div> -->
                        </div>
                    </div>
                    <div class="foot">
                        <div class="foot-item" v-for="v in puzzleFootList" :key="v.params">
                            <div class="it-da">
                                <span class="text-large bold">{{puzzleInfo[v.params]}}</span>
                                <span class="text-small-flex">{{v.unit}}</span>
                            </div>
                            <div class="it-db ">
                                <span class=" text-small-flex">{{v.title}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import leProgress from '../components/progress.vue'
    import apiClass from '@/API/page/personal'
    export default {
        name: '',
        props: {
            appStyle: Object
        },
        data() {
            return {
                api: new apiClass(),
                gameStiList: [
                    {title: '全部', value: 0},
                    {title: '近5局', value: 1},
                    {title: '近20局', value: 2},
                ],
                puzzleStiList: [
                    {title: '全部', value: -1},
                    {title: '近50局', value: 50},
                    {title: '近100局', value: 100},
                ],
                puzzleFootList: [
                    {title: '近7天', unit: '题', params: 'userExerciseD07Count'},
                    {title: '近30天', unit: '题', params: 'userExerciseD30Count'},
                    {title: '累计', unit: '题', params: 'userExerciseCount'},
                ],
                gameFootList: [
                    {title: '近7天', unit: '局', params: 'userGameDay07Count'},
                    {title: '近30天', unit: '局', params: 'userGameDay30Count'},
                    {title: '累计', unit: '局', params: 'userGameCount'},
                ],
                gameVal: 0,
                puzzleInfo: {},
                gameInfo: {},
                configInfo: [],
                routeData: this.$route.params.routeData,
            };
        },
        methods: {
            async pageInit(){
                this.eventSet()
                await this.configInit()
                await this.valueCheck()
                await this.dataCountInit()

            },
            async dataCountInit(){
                return Promise.all([this.puzzleCountInit(), this.gameCountInit()])
            },
            async puzzleCountInit(){
                let {usercode: user_code, puzzleSelected: {value: exercise_size = -1} = {}} = this
                let res = await this.api.puzzleInfo({user_code, exercise_size})
                if(res) this.puzzleInfo = res
            },
            async gameCountInit(){
                let {usercode: user_code, gameSelected: {value: game_size = -1} = {}} = this
                let res = await this.api.gameInfo({user_code, game_size})
                if(res) this.gameInfo = res
            },
            async configInit(){
                let res = await this.api.configLoad('personal')
                if(res) this.configInfo = res
            },
            async valueCheck(){
                let gameVal = this.gameValueCheck()
                let puzzleVal = this.puzzleValueCheck()
                let items = {...gameVal, ...puzzleVal}
                await this.refreshPage({items})
                return true
            },
            gameValueCheck(){
                let {gameSelected: {value}} = this
                if(!value){
                    let {gameCountList: list = []} = this
                    let {value: val} = list[0] || {}
                    value = val
                }
                let items = {gameCountValue: value}
                return items
            },
            puzzleValueCheck(){
                let {puzzleSelected: {value}} = this
                if(!value){
                    let {puzzleCountList: list = []} = this
                    let {value: val} = list[0] || {}
                    value = val
                }
                let items = {puzzleCountValue: value}
                return items
            },
            refreshPage(opt){
                opt = opt || {}
                let {items, name, type} = opt
                type = type || 'replace'
                return this._common.refreshPage.call(this, {items, name, type})
            },
            setParams(items){
                return this._common.setParams.call(this, items)
            },
            refreshPageAction(opt){
                opt = opt || {}
                let {name, params, type} = opt
                name = name || 'personal-summary'
                type = type || 'replace'
                return this._common.refreshPageAction.call(this, {name, params, type})
            },
            gameChamge(val){
                let {value: gameCountValue} = val || {}
                let items = {gameCountValue}
                this.refreshPage({items})
            },
            puzzleChamge(val){
                let {value: puzzleCountValue} = val || {}
                let items = {puzzleCountValue}
                this.refreshPage({items})
            },
            eventSet(){
                this.eventOff()
                this._common.eventSet('onresize', e => {
                    this.sizeChange()
                })
            },
            eventOff(){
                this._common.eventRemove('onresize')
            },
            sizeChange(){
                if(this.$refs['game-pie']) this.$refs['game-pie'].sizeChange()
                if(this.$refs['puzzle-pie']) this.$refs['puzzle-pie'].sizeChange()
            }
        },
        created() {
            this.pageInit()
        },
        components: {
            // leProgress
        },
        computed: {
            routeDataCul(){
                let {routeData} = this
                return this._common.routeDataCul(routeData)
            },
            puzzleCountValue(){
                let {puzzleCountValue} = this.routeDataCul
                return puzzleCountValue
            },
            gameCountValue(){
                let {gameCountValue} = this.routeDataCul
                return gameCountValue
            },
            puzzleSelected(){
                let {puzzleCountList: list, puzzleCountValue: value} = this
                let rd = {}
                for(let i in list){
                    if(list[i].value == value){
                        rd = list[i]
                        break
                    }
                }
                return rd
            },
            gameSelected(){
                let {gameCountList: list, gameCountValue: value} = this
                let rd = {}
                for(let i in list){
                    if(list[i].value == value){
                        rd = list[i]
                        break
                    }
                }
                return rd
            },
            userGameWinRate(){
                let {gameInfo: {winRate} = {}} = this
                winRate = Number(winRate)
                if(isNaN(winRate)) winRate = 0
                winRate = winRate * 100
                return winRate
            },
            userGameCount(){
                let {gameInfo: info} = this
                return info.userGameCount || 0
            },
            userGameD07Count(){
                let {gameInfo: info} = this
                return info.userGameD07Count || 0
            },
            userGameD30Count(){
                let {gameInfo: info} = this
                return info.userGameD30Count || 0
            },
            userPuzzleWinRate(){
                // let {puzzleInfo: info} = this
                // return info.userExerciseCorrectRate || 0

                let {puzzleInfo: {userExerciseCorrectRate: winrate} = {}} = this
                winrate = Number(winrate)
                if(isNaN(winrate)) winrate = 0
                winrate = winrate * 100
                return winrate
            },
            userExerciseD07Count(){
                let {puzzleInfo: info} = this
                return info.userExerciseD07Count || 0
            },
            userExerciseD30Count(){
                let {puzzleInfo: info} = this
                return info.userExerciseD30Count || 0
            },
            userExerciseCount(){
                let {puzzleInfo: info} = this
                return info.userExerciseCount || 0
            },
            puzzleCountList(){
                let {configInfoCul: list} = this
                let type = 'puzzle'
                let rd = []
                for(let i in list){
                    if(list[i].type == type){
                        rd.push(list[i])
                    }
                }
                return rd
            },
            gameCountList(){
                let {configInfoCul: list} = this
                let type = 'game'
                let rd = []
                for(let i in list){
                    if(list[i].type == type){
                        rd.push(list[i])
                    }
                }
                return rd
            },
            configInfoCul(){
                let {configInfo: list} = this
                list = this._common.deepCopy(list)
                for(let i in list){
                    let {resourceDetail = {}} = list[i]
                    list[i] = {...resourceDetail}
                }
                return list
            },
            usercode(){
                return this._userHand.usercodeGet()
            },
            nickname(){
                let {
                    app: {nickname} = {}
                } = this.$store.state
                return nickname
            },
        },
        watch: {
            appStyle: {
                handler(val){
                    this.sizeChange()
                },
                deep: true,
                immediate: true
            },
            $route: {
                handler(){
                    this.routeData = this.$route.params.routeData
                },
                deep: true,
                immediate: true
            },
            puzzleCountValue: {
                handler(){
                    this.puzzleCountInit()
                },
                deep: true,
                immediate: true
            },
            gameCountValue: {
                handler(){
                    this.gameCountInit()
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.eventOff()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .page-personal-summary{
        padding-right: @app-padding-side;
        .summary-head{
            margin-top: 5px;
            color: #FFFFFF;
        }
        .summary-content{
            display: flex;
            justify-content: space-between;
            margin: 22px auto 0px;
            width: 567px;
            height: 267px;
            border-radius: 12px;
            background: #fff;
            padding: 12px 7px 7px 13px;
            .head, .body{
                padding-left: 3px;
                display: flex;
                align-items: center;
            }
            .head{
                height: 20px;
                line-height: 20px;
            }
			.dotLineBox{
				height: 25px;
				width: 100%;
				display: flex;
				padding-left: 138px;
				align-items: flex-end;
				justify-content: flex-start;
				.dot-line{
					width: 60px;
					height: 12px;
					background: url('@{assetsUrl}/img/wht/summary-line.png') no-repeat;
				}
				.dot-text{
					height: 25px;
					width: 52px;
					position: relative;
					color: #7DB5FF;
					.b-text{
						position: absolute;
						bottom: 0;
						display: flex;
						align-items: flex-end;
						.rate-big{
							height: 24px;
							line-height: 24px;
							font-size: 24px;
							
						}
						.rate-small{
							height: 12px;
							line-height: 12px;
						}
						.s-text{
							position: absolute;
							/* width: 52px; */
							top: 24px;
							left: 50%;
							transform: translateX(-50%);
							width: 52px;
						}
					}
					
				}
				
			}
            .body{
                /* margin-top: 25px; */
                padding-top: 32px;
                .b-i-item{
                    width: 62px;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    background: url('@{assetsUrl}/img/personal/p1.png') no-repeat;
                    background-size: 100% 100%;
                    padding-right: 10px;
                    color: #7DB5FF;
                    span[class^='text']{
                        // float: left;
                        // height: auto;
                        // display: flex;
                        // align-items: center;
                        // justify-content: center;
                        // height: 100%;
                        // width: 100%;
                    }
                }
                .b-i-item+ .b-i-item{
                    margin-top: 14px;
                }
                .b-i-item.active{
                    background: url('@{assetsUrl}/img/personal/p2.png') no-repeat;
                    background-size: 100% 100%;
                    color: #fff;
                }
                .body-item.b{
                    margin-left: 23px;
                    width: 100px;
                    height: 100px;
                    position: relative;
                    .pie-chat{
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        .chat-value{
                            position: absolute;
                            left: calc(50% + 1px);
                            top: -12px;
                            height: 12px;
                            display: flex;
                            align-items: center;
                            .value-line{
                                width: 60px;
                                height: 100%;
                                background: url('@{assetsUrl}/img/personal/line.png') no-repeat;
                                background-size: 100% 100%;
                                z-index: 2;
                            }  
                            .value-text{
                                color: #7DB5FF;
                                width: 52px;
                                height: 24px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-top: -11px;
                                position: relative;
                                .text-v{
                                    display: flex;
                                    align-items: flex-end;
                                    justify-content: center;
                                }
                                .text-i{
                                    position: absolute;
                                    bottom: -12px;
                                    width: 100%;
                                    text-align: center;
                                    span[class^='text']{
                                        width: 100%;
                                        justify-content: center;
                                    }
                                }
                            } 
                        }
                    }
                    .pie-text{
                        position: absolute;
                        z-index: 2;
                        color: #fff;
                        left: 0px;
                        width: 100%;
                        bottom: 6px;
                    }
                }
            }
            .foot{
                width: 264px;
                height: 68px;
                background: #F2F2F2;
                border-radius: 8px;
                margin-top: 28px;
                display: flex;
                align-items: center;
                padding: 16px 0px;
                .foot-item{
                    width: calc(100% / 3);
                    text-align: center;
                    .it-db{
                        color: #919191;
						
                    }
                }
            }
            .title-text{
                height: 20px;
            }
            .title-icon{
                margin-right: 4px;
                width: 6px;
                height: 16px;
            }
        }
    }
</style>